import React from 'react';

const ServicesOutsourcing = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0509 14.8093C18.1797 14.8093 17.3283 14.551 16.6039 14.067C15.8796 13.583 15.315 12.8952 14.9816 12.0904C14.6482 11.2856 14.5609 10.3999 14.7309 9.54551C14.9008 8.6911 15.3204 7.90612 15.9364 7.29013C16.5524 6.67413 17.3371 6.25457 18.1915 6.08461C19.0459 5.91466 19.9315 6.00197 20.7363 6.33534C21.5412 6.66871 22.2293 7.23336 22.7133 7.95769C23.1972 8.68202 23.4555 9.5335 23.4555 10.4046C23.4555 10.9831 23.3415 11.556 23.1202 12.0904C22.8988 12.6248 22.5744 13.1102 22.1654 13.5192C21.7564 13.9282 21.2707 14.2526 20.7363 14.474C20.202 14.6953 19.6293 14.8093 19.0509 14.8093ZM19.0509 7.15911C18.409 7.15911 17.7814 7.34949 17.2477 7.70611C16.714 8.06273 16.298 8.5696 16.0524 9.16263C15.8067 9.75567 15.7424 10.4084 15.8676 11.038C15.9928 11.6675 16.302 12.2458 16.7559 12.6996C17.2098 13.1535 17.788 13.4627 18.4176 13.5879C19.0471 13.7132 19.6999 13.6488 20.2929 13.4031C20.8859 13.1575 21.3928 12.7415 21.7494 12.2078C22.106 11.6741 22.2964 11.0465 22.2964 10.4046C22.2995 9.9765 22.2176 9.552 22.0559 9.15556C21.8942 8.75912 21.6558 8.39876 21.3541 8.09493C21.0524 7.7911 20.6936 7.54976 20.2983 7.38521C19.903 7.22066 19.479 7.13589 19.0509 7.1359V7.15911Z"
      fill="#475467"
    />
    <path
      d="M17.8686 41.6149H16.2921C16.1384 41.6149 15.9911 41.5538 15.8824 41.4451C15.7737 41.3364 15.7126 41.1891 15.7126 41.0353V29.0733H13.6725C13.5265 29.0738 13.3858 29.0191 13.2783 28.9202C13.1709 28.8214 13.1047 28.6857 13.093 28.5402L12.0035 17.3199C11.9898 17.1985 12.0165 17.076 12.0793 16.9712C12.1422 16.8665 12.2377 16.7854 12.3513 16.7403L14.5306 15.7901C14.673 15.7395 14.8288 15.742 14.9695 15.7974C15.1101 15.8528 15.2258 15.9573 15.2955 16.0914C15.3514 16.2334 15.3514 16.3912 15.2955 16.5332C15.2395 16.6752 15.1318 16.7907 14.9941 16.8563L13.209 17.6212L14.206 27.9142H16.2921C16.4458 27.9142 16.5935 27.9753 16.7022 28.084C16.8109 28.1927 16.8717 28.3401 16.8717 28.4938V40.4558H17.8686C18.0223 40.4558 18.1697 40.5166 18.2784 40.6253C18.3871 40.734 18.4482 40.8816 18.4482 41.0353C18.4482 41.1891 18.3871 41.3364 18.2784 41.4451C18.1697 41.5538 18.0223 41.6149 17.8686 41.6149Z"
      fill="#475467"
    />
    <path
      d="M21.8789 41.6083H20.3027C20.149 41.6083 20.0013 41.5472 19.8927 41.4385C19.784 41.3298 19.7231 41.1825 19.7231 41.0288C19.7231 40.8751 19.784 40.7274 19.8927 40.6187C20.0013 40.51 20.149 40.4492 20.3027 40.4492H21.8789C22.0326 40.4492 22.18 40.51 22.2887 40.6187C22.3974 40.7274 22.4585 40.8751 22.4585 41.0288C22.4585 41.1825 22.3974 41.3298 22.2887 41.4385C22.18 41.5472 22.0326 41.6083 21.8789 41.6083Z"
      fill="#475467"
    />
    <path
      d="M25.1245 22.5531C24.9729 22.5357 24.8335 22.4616 24.7346 22.3454C24.6356 22.2292 24.5845 22.0796 24.5914 21.9272L25.0085 17.6153L23.2237 16.8504C23.1535 16.8202 23.0899 16.7763 23.0367 16.7214C22.9835 16.6665 22.9419 16.6018 22.9139 16.5307C22.8859 16.4596 22.8719 16.3835 22.8734 16.3071C22.8748 16.2307 22.8917 16.1555 22.9224 16.0855C22.988 15.9478 23.1033 15.8401 23.2452 15.7841C23.3872 15.7282 23.5453 15.7282 23.6873 15.7841L25.843 16.7344C25.9566 16.7794 26.0521 16.8606 26.115 16.9653C26.1778 17.0701 26.2045 17.1926 26.1908 17.314L25.7505 21.9504C25.754 22.0325 25.74 22.1146 25.7095 22.1909C25.6789 22.2672 25.6325 22.336 25.5733 22.393C25.5142 22.45 25.4436 22.4938 25.3662 22.5214C25.2888 22.549 25.2064 22.5598 25.1245 22.5531Z"
      fill="#475467"
    />
    <path
      d="M27.7211 41.0514C26.0787 41.056 24.4718 40.5734 23.1039 39.6642C21.7361 38.7551 20.6689 37.4605 20.0372 35.9444C19.4055 34.4283 19.2377 32.759 19.5553 31.1475C19.8729 29.5361 20.6617 28.0553 21.8214 26.8923C22.9812 25.7293 24.4596 24.9364 26.0702 24.6143C27.6807 24.2922 29.3506 24.4552 30.8684 25.0826C32.3863 25.7101 33.6838 26.7739 34.5967 28.1391C35.5097 29.5044 35.9969 31.1099 35.9969 32.7524C35.997 34.9494 35.126 37.0569 33.5746 38.6127C32.0232 40.1684 29.9181 41.0453 27.7211 41.0514ZM27.7211 25.6356C26.3069 25.631 24.9232 26.0464 23.7454 26.8292C22.5677 27.612 21.649 28.7267 21.1058 30.0323C20.5625 31.3379 20.4193 32.7755 20.694 34.1627C20.9688 35.5499 21.6492 36.8243 22.6492 37.8243C23.6491 38.8242 24.9235 39.5046 26.3107 39.7794C27.6979 40.0542 29.1355 39.911 30.4411 39.3677C31.7467 38.8244 32.8614 37.9057 33.6442 36.728C34.427 35.5503 34.8424 34.1665 34.8378 32.7524C34.8317 30.8667 34.0799 29.0602 32.7466 27.7268C31.4132 26.3935 29.6067 25.6417 27.7211 25.6356Z"
      fill="#475467"
    />
    <path
      d="M27.7208 37.3885C27.5671 37.3885 27.4197 37.3274 27.311 37.2187C27.2023 37.11 27.1412 36.9627 27.1412 36.809V28.7184C27.1241 28.6343 27.126 28.5474 27.1466 28.464C27.1672 28.3807 27.206 28.3027 27.2604 28.2362C27.3147 28.1697 27.3834 28.1163 27.461 28.0795C27.5386 28.0427 27.6233 28.0234 27.7092 28.0234C27.7951 28.0234 27.88 28.0427 27.9576 28.0795C28.0352 28.1163 28.1036 28.1697 28.158 28.2362C28.2123 28.3027 28.2511 28.3807 28.2717 28.464C28.2924 28.5474 28.2942 28.6343 28.2771 28.7184V36.8322C28.2714 36.9779 28.211 37.1162 28.1079 37.2193C28.0048 37.3224 27.8665 37.3828 27.7208 37.3885Z"
      fill="#475467"
    />
    <path
      d="M31.7777 33.3544H23.6408C23.4912 33.3484 23.3498 33.2847 23.2461 33.1767C23.1423 33.0687 23.0844 32.9246 23.0845 32.7749C23.0844 32.6251 23.1423 32.4811 23.2461 32.373C23.3498 32.265 23.4912 32.2013 23.6408 32.1953H31.7777C31.9296 32.201 32.0738 32.2639 32.1813 32.3713C32.2887 32.4788 32.3516 32.623 32.3573 32.7749C32.3573 32.9286 32.2962 33.0759 32.1875 33.1846C32.0788 33.2933 31.9314 33.3544 31.7777 33.3544Z"
      fill="#475467"
    />
  </svg>
);

export default ServicesOutsourcing;
